<script>
import appConfig from "@/app.config";
import Vue from 'vue'
import {required,email} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-right',
  showConfirmButton: false,
  animation: false,
  timer: 2000,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }

})


export default {
  page: {
    title: "Login",
    meta: [{name: "description", content: appConfig.description}]
  },
  name: 'Login',
  data() {
    return {
      typesubmit: false,
      user: {
        email: '',
        password: '',
        remamber_me:false,
      }

    }
  },
  validations: {
    user:{
      email: {required,email},
      password: {required},
    },
  },

  methods: {
    handleSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.login();

    },

    login() {
      let msg_success = this.$t('logins.welcome')
      let msg_error = this.$t('logins.error')
      this.$http
              .post('login', this.user)
              .then(response => {
                const token = response.data.token.access_token;
                this.$store.commit('user',response.data.user.profil)
                localStorage.setItem('token', token)
                Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
                Vue.prototype.$koth.defaults.headers.common['Authorization'] = 'Bearer ' + token
                this.$store.commit('updatePusher',token)
                Toast.fire({icon: 'success', title: msg_success})
                this.$store.commit('interceptError',false)
                if(response.data.user.email_verified_at == null) this.$router.push({name:'confirmationEmail'})
                else if(response.data.user.first_time_login)this.$router.push({'name':'FirstInfos'})
                else {
                  let redirect_url = this.$route.query.redirect || '/dashboard'
                  console.log(redirect_url);
                  this.$router.push(redirect_url)
                }
              })
              .catch(error => {
                console.log(error)
                Toast.fire({icon: 'error', title: 'Oops...', text: msg_error})
              })
    }
  }
}

</script>

<template>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="card-body pt-0">
                <div class="text-center mt-4 pt-4 mb-4">
                     <img src="@/assets/images/logo-light-icon.png" alt   role="button"  @click="$router.push({'name':'Crisi5'})"  width="100" />
                </div>
                <div class="p-2">
                  <form class="form-horizontal" id="form"  method="POST" @keyup.enter="handleSubmit" @submit.prevent="">
                    <div class="form-group">
                      <label for="email">{{$t('logins.email')}}</label>
                      <input   type="email" class="form-control" v-model="user.email" id="email"
                             :placeholder="$t('logins.email_text')"
                             :class="{ 'is-invalid': typesubmit && $v.user.email.$error }"/>
                      <div v-if="typesubmit && $v.user.email.$error" class="invalid-feedback">
                        <span v-if="!$v.user.email.required"> {{$t('validation.required')}}</span>
                        <span
                                v-else-if="!$v.user.email.email"
                        >{{$t('validation.email')}}</span>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="userpassword">{{$t('logins.password')}}</label>
                      <input  type="password"  class="form-control" v-model="user.password" id="userpassword"
                             :placeholder="$t('logins.password_text')"
                             :class="{ 'is-invalid': typesubmit && $v.user.password.$error }"/>
                      <div v-if="typesubmit && $v.user.password.$error" class="invalid-feedback">
                        <span v-if="!$v.user.password.required"> {{$t('validation.required')}}</span>
                      </div>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customControlInline" v-model="user.remamber_me"/>
                      <label class="custom-control-label" for="customControlInline">{{$t('logins.remember')}}</label>
                    </div>
                    <div class="mt-3">
                      <input type="button"  class="btn btn-primary btn-block"  :value="$t('logins.login')" @click="handleSubmit"/>
                    </div>
                    <div class="mt-4 ">
                      <router-link :to="{'name':'RecoverPassword'}" class="text-muted">
                        <i class="mdi mdi-lock mr-1"></i> {{$t('logins.forget')}}
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <p>
                {{$t('logins.sign_up_text')}}
                <router-link :to="{'name':'Register'}" class="font-weight-medium text-primary">{{$t('logins.sign_up')}}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>